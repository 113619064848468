var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RadioGroup, FormControl, Radio, FormControlLabel, Checkbox, FormLabel, FormGroup, Button, ClickAwayListener, Tooltip, } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useState, useEffect, useContext, Fragment } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import InputNormal from '../input/InputNormal';
import { autoHypenPhone, makeErrorTextAndPlaceholder, setDateFormat } from '../../utils/tools';
import { CheckBoxOutlineBlankOutlined, Square } from '@mui/icons-material';
import { ModalContext } from '../../provider/ModalProvider';
import validation from '../../utils/validation';
import InfoIcon from '../../components/common/InfoIcon';
import { useSelector } from 'react-redux';
// style
var style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '29rem',
    height: 'auto',
    boxShadow: 24,
};
var StyledTooltip = styled(function (_a) {
    var isZoom = _a.isZoom, className = _a.className, props = __rest(_a, ["isZoom", "className"]);
    return (_jsx(Tooltip, __assign({}, props, { arrow: true, classes: { popper: className }, disableHoverListener: true }), void 0));
})(function (_a) {
    var _b;
    var theme = _a.theme, placement = _a.placement, isZoom = _a.isZoom;
    return (_b = {},
        _b["& .".concat(tooltipClasses.arrow)] = {
            color: '#3f3f3f',
            left: placement === 'top-start' ? '1.5rem !important' : '0px !important',
        },
        _b["& .".concat(tooltipClasses.tooltip)] = {
            backgroundColor: '#3f3f3f',
            fontSize: '1rem',
            maxWidth: 'unset',
            transform: isZoom ? 'scale(0.9) !important' : 'none',
        },
        _b.inset = placement === 'top-start' ? 'auto auto 0px -1.6rem !important' : 'unset',
        _b);
});
var StyledFormControl = styled(FormControl)({
    borderRadius: '8px',
    padding: '2.5rem 1.5rem 1.25rem 2.5rem',
    width: '100%',
    maxWidth: '500px',
    margin: 'auto',
    backgroundColor: 'var(--white)',
    '&:focus-visible': {
        outline: 'none',
    },
});
var StyledFormLabel = styled(FormLabel)({
    color: 'var(--black)',
    fontSize: '1.125rem',
    marginBottom: '0.75em',
    fontWeight: '700',
    '&.Mui-focused': {
        color: 'var(--black)',
    },
});
var StyledSubtitle = styled(Box)(function (props) {
    var isBlack = false;
    if (props.subtitle) {
        isBlack = ['(청구일 포함)', '(매년 1월 1일 자동 상향)', '(최근 6개월)'].includes(props.subtitle);
    }
    return {
        fontWeight: '500',
        fontSize: '0.9rem',
        color: isBlack ? 'var(--black)' : 'var(--gray-2)',
    };
});
var StyledRadioGroup = styled(RadioGroup)(function () { return ({
    paddingRight: '2.125rem',
    width: '100%',
    maxHeight: '220px',
    overflowX: 'hidden',
    overflowY: 'auto',
    display: 'block',
}); });
var StyledFormGroup = styled(FormGroup)(function () { return ({
    paddingRight: '2.125rem',
    width: '100%',
    maxHeight: '220px',
    overflowX: 'hidden',
    overflowY: 'auto',
    display: 'block',
}); });
var StyledFormControlLabel = styled(FormControlLabel)(function () { return ({
    width: '100%',
    color: 'var(--gray-1)',
    marginTop: '0.25rem',
    '&.MuiFormControlLabel-root': {
        marginRight: '1rem',
        marginLeft: '1rem',
    },
    '& .MuiTypography-root': {
        fontSize: '1.125rem',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        marginRight: 'auto',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            '& > span:first-of-type': {
                width: '20%',
            },
            '& > span:last-of-type': {
                fontSize: '0.85rem',
            },
        },
    },
    '.disabled_box': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
}); });
var StyledRadio = styled(Radio)(function () { return ({
    '&.MuiRadio-root': {
        padding: '7px',
    },
    '& .MuiSvgIcon-root': {
        color: 'var(--gray-1)',
    },
    '&.Mui-checked': {
        '& .MuiSvgIcon-root': {
            color: 'var(--green)',
        },
    },
}); });
var StyledCheckbox = styled(Checkbox)(function () { return ({
    '&.MuiCheckbox-root': {
        padding: '7px',
        '.checkbox_square': {
            color: '#e0e0e0',
        },
    },
}); });
var StyledButton = styled(Button)(function () { return ({
    marginRight: 'calc(1.215rem - (64px / 4) + 2px)',
    marginLeft: 'auto',
    fontSize: '1.125rem',
    marginTop: '0.75rem',
    alignItems: 'start',
    justifyContent: 'center',
    width: '10%',
    height: '2.5rem',
    transition: 'none',
    '&:hover': {
        backgroundColor: 'transparent',
    },
    '&.scroll': {
        marginRight: 'calc(1.215rem - (64px / 4) + 8px)',
    },
}); });
function ModalSelect(props) {
    var _a, _b, _c, _d, _e;
    var user_info = useSelector(function (state) { return state.UserReducer; });
    var _f = useContext(ModalContext), modal_alert = _f.modal_alert, modal_confirm = _f.modal_confirm;
    var _g = useState([]), value = _g[0], setValue = _g[1];
    var _h = useState([]), contents = _h[0], setContents = _h[1];
    var _j = useState([]), oldValue = _j[0], setOldValue = _j[1];
    var _k = useState(''), inputValue = _k[0], setInputValue = _k[1];
    var _l = useState(false), noSelect = _l[0], setNoSelect = _l[1];
    var _m = useState(false), hasScroll = _m[0], setHasScroll = _m[1];
    var visible = props.visible;
    var modal_id = (_a = props.data) === null || _a === void 0 ? void 0 : _a.modal_id;
    var title = (_b = props.data) === null || _b === void 0 ? void 0 : _b.title;
    var subtitle = (_c = props.data) === null || _c === void 0 ? void 0 : _c.subtitle;
    var type = (_d = props.data) === null || _d === void 0 ? void 0 : _d.type;
    var selected_data = (_e = props.data) === null || _e === void 0 ? void 0 : _e.selectedData;
    var old_value = props.currentInput;
    var changeValue = props.onChange;
    var offModal = props.onClick;
    var onCancel = props.onCancel;
    var isMobile = props.isMobile;
    var getDefaultValue = function () {
        if (selected_data) {
            var default_value = void 0;
            if (modal_id === 'charge_date' || modal_id === 'upcheck' || modal_id === 'presendday') {
                default_value = contents.find(function (item) {
                    return item.name.split('///')[0] === selected_data;
                });
            }
            else {
                default_value = contents.find(function (item) { return item.name === selected_data; });
            }
            if (default_value) {
                if (String(default_value.id).includes('direct_')) {
                    return default_value.name;
                }
                return default_value.id;
            }
            else {
                return 'etc';
            }
        }
        else {
            return '';
        }
    };
    useEffect(function () {
        if (visible) {
            var tmp_contents = __spreadArray([], props.data.contents, true);
            var tmp_old_value = __spreadArray([], old_value, true);
            if (type == 'checkbox') {
                var idx_arr_1 = [];
                var _loop_1 = function (item) {
                    var check_idx = tmp_contents.findIndex(function (content) { return content.id == item.id; });
                    if (Number(check_idx) >= 0) {
                        idx_arr_1.push(Number(check_idx));
                    }
                };
                for (var _i = 0, tmp_old_value_1 = tmp_old_value; _i < tmp_old_value_1.length; _i++) {
                    var item = tmp_old_value_1[_i];
                    _loop_1(item);
                }
                var find_all_idx = tmp_contents.findIndex(function (item) { return item.id == 'all'; });
                if (Number(find_all_idx) >= 0 && tmp_old_value.length == tmp_contents.length - 1) {
                    idx_arr_1.push(find_all_idx);
                    tmp_old_value.push({ id: 'all', name: tmp_contents[find_all_idx].name });
                }
                var find_all_no_idx = tmp_old_value.findIndex(function (item) { return item.id == 'all_no'; });
                if (Number(find_all_no_idx) >= 0) {
                    setNoSelect(true);
                }
                setContents(__spreadArray([], tmp_contents.map(function (contents_item, contents_idx) {
                    return __assign(__assign({}, contents_item), { checked: idx_arr_1.includes(contents_idx) });
                }), true));
            }
            else {
                setContents(__spreadArray([], tmp_contents, true));
                if (tmp_old_value.length > 0 && "".concat(tmp_old_value[0].id).includes('direct_')) {
                    setInputValue("".concat(tmp_old_value[0].name));
                }
            }
            setOldValue(__spreadArray([], tmp_old_value, true));
            setValue(__spreadArray([], tmp_old_value, true));
            setTimeout(function () {
                var el = document.getElementById('wrap_el');
                if (el) {
                    if (el.scrollHeight > el.clientHeight) {
                        setHasScroll(true);
                    }
                    else {
                        setHasScroll(false);
                    }
                }
            }, 0);
        }
        else {
            setHasScroll(false);
            setContents([]);
            setValue([]);
            setInputValue('');
            setNoSelect(false);
        }
    }, [visible]);
    useEffect(function () {
        if (hasScroll) {
            var el_1 = document.getElementById('wrap_el');
            if (oldValue.length > 0 && el_1) {
                var old_id_1 = oldValue[0].id;
                var old_idx_1 = contents.findIndex(function (item) { return item.id == old_id_1; });
                setTimeout(function () {
                    if (Number(old_idx_1) >= 0) {
                        var old_value_el = document.getElementById("radio_".concat(old_idx_1));
                        if (old_value_el) {
                            el_1.scrollTo({ top: old_value_el.offsetTop - old_value_el.offsetHeight * 2, behavior: 'smooth' });
                        }
                    }
                }, 50);
            }
        }
    }, [hasScroll]);
    useEffect(function () {
        // ModalSelect의 label 길이가 매우 길어졌을 때, 말줄임 처리 및 툴팁 표시
        setTimeout(function () {
            if (contents.length) {
                var label_array = Array.from(document.getElementsByClassName('MuiFormControlLabel-label'));
                for (var i = 0; i < label_array.length; i++) {
                    var label = label_array[i];
                    if (label.offsetWidth < label.scrollWidth) {
                        var title_1 = props.data.contents[i]['rname'] || props.data.contents[i]['name'];
                        if (typeof title_1 === 'string') {
                            label.setAttribute('title', title_1);
                        }
                    }
                }
            }
        }, 0);
    }, [contents]);
    var onChangeRadio = function (e) {
        var id = e.target.value;
        if (id.includes('direct_')) {
            var type_1 = id.replace(/direct_/gi, '');
            var tmp_contents_1 = __spreadArray([], contents.map(function (item) { return (__assign({}, item)); }), true);
            var direct_content_1 = tmp_contents_1.find(function (item) { return item.id === id; });
            var input_type = id.replace(/direct_/, '');
            var target = '';
            var modal_confirm_content = 'set_direct_input';
            var confrim_callback = function () {
                //
            };
            var cancel_callback = function () {
                //
            };
            if (input_type === 'phone') {
                target = '핸드폰 번호를';
                confrim_callback = function () {
                    if (direct_content_1) {
                        direct_content_1.visibleInput = true;
                        setValue([__assign({}, direct_content_1)]);
                    }
                    setContents(__spreadArray([], tmp_contents_1, true));
                };
                cancel_callback = function () {
                    setValue(__spreadArray([], value, true));
                };
            }
            if (inputValue !== '' && validation[type_1](inputValue) && (direct_content_1 === null || direct_content_1 === void 0 ? void 0 : direct_content_1.visibleInput) === false) {
                modal_confirm_content = 'update_direct_input';
            }
            modal_confirm.openModalConfirm('normal', modal_confirm_content, confrim_callback, cancel_callback, target);
        }
        else {
            var tmp_contents = __spreadArray([], contents.map(function (item) { return (__assign({}, item)); }), true);
            var direct_content = tmp_contents.find(function (item) { return String(item.id).includes('direct_'); });
            if (direct_content) {
                direct_content.visibleInput = false;
                setContents(__spreadArray([], tmp_contents, true));
            }
            var find_item = contents.find(function (item) { return item.id == id; });
            if (find_item) {
                setValue([find_item]);
            }
        }
    };
    var onClickDirectRadio = function () {
        modal_confirm.openModalConfirm('normal', 'update_direct_input', function () {
            var tmp_contents = __spreadArray([], contents.map(function (item) { return (__assign({}, item)); }), true);
            var direct_content = tmp_contents.find(function (item) { return String(item.id).includes('direct_'); });
            if (direct_content) {
                direct_content.visibleInput = true;
                setContents(__spreadArray([], tmp_contents, true));
            }
        }, undefined, '핸드폰 번호를');
    };
    var handleInput = function (val, id) {
        var cur_id = "".concat(id);
        var vali_type = cur_id.replace(/direct_/gi, '');
        if (['phone'].includes(vali_type)) {
            if (val.length > 13)
                return;
            var input_num = Number(val.replace(/-/g, ''));
            if (Number.isNaN(input_num)) {
                // 숫자 이외의 값 입력시 //
                modal_alert.openModalAlert('only_number');
                return;
            }
            var check = val.match(/[^0-9|^-]/gi);
            if (check && check.length > 0) {
                return;
            }
            else {
                var cur_val = autoHypenPhone(val);
                setInputValue(cur_val);
                var tmp_value = __spreadArray([], value, true);
                // tmp_value[0].name = cur_val;
                // setValue([...tmp_value]);
            }
        }
    };
    var onChangeCheckbox = function (check, i) {
        var val = !check;
        var tmp_value = value;
        var tmp_contents = __spreadArray([], contents, true);
        tmp_contents[i].checked = val;
        if (!val) {
            if (contents[i].id == 'all') {
                tmp_value = [];
                for (var _i = 0, tmp_contents_2 = tmp_contents; _i < tmp_contents_2.length; _i++) {
                    var content = tmp_contents_2[_i];
                    content.checked = false;
                }
            }
            else {
                var find_target = tmp_value.findIndex(function (item) { return item.id == contents[i].id; });
                if (Number(find_target) >= 0) {
                    if (tmp_value[find_target].id == 'all_no') {
                        setNoSelect(false);
                    }
                    tmp_value.splice(find_target, 1);
                }
                if (tmp_value.length < contents.length) {
                    var find_idx = tmp_value.findIndex(function (item) { return item.id == 'all'; });
                    if (Number(find_idx) >= 0) {
                        tmp_value.splice(find_idx, 1);
                        var find_content_idx = contents.findIndex(function (item) { return item.id == 'all'; });
                        if (Number(find_content_idx) >= 0) {
                            tmp_contents[find_content_idx].checked = false;
                        }
                    }
                }
            }
        }
        else {
            if (contents[i].id == 'all') {
                tmp_value = __spreadArray([], contents.map(function (item) { return ({ id: item.id, name: item.name }); }), true);
                for (var _a = 0, tmp_contents_3 = tmp_contents; _a < tmp_contents_3.length; _a++) {
                    var content = tmp_contents_3[_a];
                    content.checked = true;
                }
            }
            else if (contents[i].id == 'all_no') {
                tmp_value = [contents[i]];
                for (var _b = 0, tmp_contents_4 = tmp_contents; _b < tmp_contents_4.length; _b++) {
                    var content = tmp_contents_4[_b];
                    var cur_checked = false;
                    if (content.id == 'all_no') {
                        cur_checked = true;
                    }
                    content.checked = cur_checked;
                }
                setNoSelect(true);
            }
            else {
                tmp_value.push({ id: contents[i].id, name: contents[i].name });
                if (tmp_value.length == contents.length - 1) {
                    var find_idx = contents.findIndex(function (item) { return item.id == 'all'; });
                    if (Number(find_idx) >= 0) {
                        tmp_value.push({ id: contents[find_idx].id, name: contents[find_idx].name });
                        tmp_contents[find_idx].checked = true;
                    }
                }
            }
        }
        setValue(__spreadArray([], tmp_value.map(function (item) {
            return __assign({}, item);
        }), true));
        setContents(__spreadArray([], tmp_contents, true));
    };
    var onClick = function () {
        if (checkValue()) {
            var tmp_value = __spreadArray([], value, true);
            if (type == 'checkbox') {
                var check_all_idx = tmp_value.findIndex(function (item) { return item.id == 'all'; });
                if (Number(check_all_idx) >= 0)
                    tmp_value.splice(check_all_idx, 1);
            }
            if (type == 'radio' && tmp_value.length > 0 && "".concat(tmp_value[0].id).includes('direct_')) {
                tmp_value[0].name = inputValue;
                if ("".concat(tmp_value[0].id) == 'direct_phone') {
                    var cur_num = inputValue.replace(/[^0-9]/gi, '');
                    var list_num_arr = contents.map(function (item) { return item.name.replace(/[^0-9]/gi, ''); });
                    if (list_num_arr.includes(cur_num)) {
                        modal_alert.openModalAlert('write_another_number', undefined);
                        return;
                    }
                }
            }
            changeValue(tmp_value, isMobile);
            // if (modal_id == 'period_format') return;
        }
        else {
            if (onCancel) {
                onCancel();
            }
        }
        offModal();
    };
    var checkValue = function () {
        var result = false;
        if (type == 'radio') {
            if (oldValue.length == 0 && value.length > 0 && "".concat(value[0].id).includes('direct_')) {
                // 처음 직접 입력 클릭 시,
                var type_2 = value[0].id.replace(/direct_/gi, '');
                var isError = !validation[type_2](inputValue);
                if (isError || !inputValue) {
                    result = false;
                }
                else {
                    result = true;
                }
            }
            else if (oldValue.length > 0 && value.length > 0) {
                if (oldValue[0].id == value[0].id) {
                    if ("".concat(oldValue[0].id).includes('direct_')) {
                        var type_3 = value[0].id.replace(/direct_/gi, '');
                        var isError = !validation[type_3](inputValue);
                        if (oldValue[0].name == inputValue || !inputValue) {
                            result = false;
                        }
                        else if (isError) {
                            result = false;
                        }
                        else {
                            result = true;
                        }
                    }
                    else {
                        result = false;
                    }
                }
                else if ("".concat(value[0].id).includes('direct_')) {
                    var type_4 = value[0].id.replace(/direct_/gi, '');
                    var isError = !validation[type_4](inputValue);
                    if (isError || !inputValue) {
                        result = false;
                    }
                    else {
                        result = true;
                    }
                }
                else {
                    result = true;
                }
            }
            else {
                if (value.length > 0)
                    result = true;
                else
                    result = false;
            }
        }
        else if (type == 'checkbox') {
            if (oldValue.length > 0 && value.length > 0) {
                if (oldValue.length == value.length) {
                    var check_cnt = 0;
                    var _loop_2 = function (val) {
                        var old_idx = oldValue.findIndex(function (old) { return old.id == val.id; });
                        if (Number(old_idx) >= 0) {
                            check_cnt++;
                        }
                        else {
                            result = true;
                        }
                    };
                    for (var _i = 0, value_1 = value; _i < value_1.length; _i++) {
                        var val = value_1[_i];
                        _loop_2(val);
                    }
                    if (check_cnt == value.length) {
                        result = false;
                    }
                }
                else {
                    result = true;
                }
            }
            else {
                if (oldValue.length > 0) {
                    result = true;
                }
                else {
                    if (value.length > 0)
                        result = true;
                    else
                        result = false;
                }
            }
        }
        return result;
    };
    var setTooltipOpen = function (open, index) {
        var tmp_contents = __spreadArray([], contents, true).map(function (content, idx) {
            if (idx === index) {
                var newOpen = content.tooltip != undefined ? !content.tooltip.open : open;
                return __assign(__assign({}, content), { tooltip: content.tooltip
                        ? __assign(__assign({}, content.tooltip), { open: newOpen }) : undefined });
            }
            else {
                return __assign(__assign({}, content), { tooltip: content.tooltip
                        ? __assign(__assign({}, content.tooltip), { open: false }) : undefined });
            }
        });
        setContents(__spreadArray([], tmp_contents, true));
    };
    var onClickOverCount = function () {
        var new_value = [
            {
                id: 'isOver',
                name: 'isOver',
            },
        ];
        changeValue(new_value, isMobile);
        offModal();
    };
    return (_jsx(Modal, __assign({ open: visible, sx: {
            zoom: "".concat(user_info.zoom_status, "%"),
            '& > .MuiBox-root': {
                '&:focus-visible': {
                    outline: 'none',
                },
                boxShadow: 'none',
            },
        }, onClose: offModal }, { children: _jsx(Box, __assign({ sx: style }, { children: _jsxs(StyledFormControl, { children: [_jsx(StyledFormLabel, { children: _jsxs(Box, { children: [title, ' ', _jsx(StyledSubtitle, __assign({ component: 'span', subtitle: subtitle }, { children: subtitle }), void 0)] }, void 0) }, void 0), type === 'radio' ? (_jsx(StyledRadioGroup, __assign({ onChange: onChangeRadio, defaultValue: getDefaultValue(), id: 'wrap_el' }, { children: contents === null || contents === void 0 ? void 0 : contents.map(function (v, i) {
                            var contents = null;
                            if ("".concat(v.id).includes('direct_')) {
                                var type_5 = v.id.replace(/direct_/gi, '');
                                var visible_input = v.visibleInput;
                                var placeholder = makeErrorTextAndPlaceholder(type_5).placeholder;
                                contents = (_jsx(StyledFormControlLabel, { id: "radio_".concat(i), style: { pointerEvents: 'none' }, value: v.id, label: visible_input ? (_jsx(InputNormal, { id: 'direct_input', placeholder: placeholder, onChange: function (e) {
                                            handleInput(e.target.value, v.id);
                                        }, inputSx: {
                                            margin: 0,
                                            input: {
                                                '&::placeholder': {
                                                    fontSize: '1rem !important',
                                                },
                                            },
                                        }, value: value[0] && value[0].id == v.id ? inputValue : '', error: value[0] && value[0].id != v.id ? false : !validation[type_5](inputValue) }, void 0)) : inputValue !== '' && validation[type_5](inputValue) ? (inputValue) : ('직접 입력'), labelPlacement: 'start', control: _jsx(StyledRadio, { style: { pointerEvents: 'auto' }, disableRipple: true, onClick: inputValue !== '' && validation[type_5](inputValue) && visible_input === false
                                            ? onClickDirectRadio
                                            : undefined, color: 'green', 
                                        // checked={visible_input || (value[0] && v.id === value[0].id)}
                                        checked: visible_input || (value[0] && v.id === value[0].id) ? true : false }, void 0), sx: { span: { pointerEvents: 'auto' } } }, "select_modal_radio_".concat(v.id)));
                            }
                            else if (['upcheck', 'charge_date', 'status', 'modal_select_period', 'presendday'].includes(modal_id)) {
                                var _a = v.name.split('///'), day = _a[0], auto_date = _a[1];
                                var color = '#d4d4d4';
                                var width = 'auto';
                                if (modal_id == 'charge_date') {
                                    var cur_date = "".concat(setDateFormat("".concat(auto_date).replace(/[^0-9]/gi, '')), " 00:00:00");
                                    var cur_month = new Date(cur_date).getMonth();
                                    var now = new Date().getMonth();
                                    width = '3rem';
                                    if (now == cur_month) {
                                        color = '#9f9f9f';
                                    }
                                }
                                else if (modal_id == 'status') {
                                    width = '4rem';
                                }
                                else if (modal_id == 'upcheck') {
                                    color = '#9f9f9f';
                                }
                                else if (modal_id == 'presendday') {
                                    var cur_date = "".concat(setDateFormat("".concat(auto_date).replace(/[^0-9]/gi, '')), " 00:00:00");
                                    // const cur_month = new Date(cur_date).getMonth();
                                    // const now = new Date().getMonth();
                                    width = 'auto';
                                    if (auto_date && auto_date.includes('사전 청구일')) {
                                        color = '#9f9f9f';
                                    }
                                }
                                contents = (_jsx(StyledFormControlLabel, { id: "radio_".concat(i), style: { pointerEvents: 'none' }, value: v.id, label: v.name ? (_jsxs(Box, __assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Box, __assign({ sx: { width: width } }, { children: day }), void 0), _jsxs(Box, __assign({ sx: {
                                                    marginLeft: modal_id == 'presendday' ? '0.5rem' : '0rem',
                                                    fontSize: '0.925rem',
                                                    color: color,
                                                } }, { children: ["\u00A0", auto_date] }), void 0)] }), void 0)) : (''), labelPlacement: 'start', control: _jsx(StyledRadio, { style: { pointerEvents: 'auto' }, disableRipple: true, color: 'green' }, void 0), sx: { span: { pointerEvents: 'auto' } } }, "select_modal_radio_".concat(v.id)));
                            }
                            else if (v.id === 'not_linkage') {
                                var tooltip = v.tooltip;
                                contents = (_jsxs(Box, __assign({ sx: { position: 'relative' } }, { children: [_jsx(StyledFormControlLabel, { id: "radio_".concat(i), style: { pointerEvents: 'none' }, value: v.id, label: v.name ? v.name : '', labelPlacement: 'start', control: _jsx(StyledRadio, { style: { pointerEvents: 'auto' }, disableRipple: true, color: 'green', checked: value[0] && v.id === value[0].id }, void 0), sx: { span: { pointerEvents: 'auto' } } }, void 0), tooltip ? (_jsx(ClickAwayListener, __assign({ onClickAway: function () {
                                                setTooltipOpen(false, i);
                                            } }, { children: _jsx(Box, __assign({ component: 'span', sx: {
                                                    ml: '0.25rem',
                                                    cursor: 'pointer',
                                                    position: 'absolute',
                                                    top: 'calc((100% - 0.25rem) * 0.5)',
                                                    left: '17.5%',
                                                    transform: 'translateY(-27.5%)',
                                                } }, { children: _jsx(StyledTooltip, __assign({ title: tooltip.text, placement: tooltip.direction, open: tooltip.open, onClose: function () {
                                                        setTooltipOpen(false, i);
                                                    }, isZoom: user_info.zoom_status === 90, onClick: function (event) {
                                                        event.stopPropagation();
                                                        setTooltipOpen(true, i);
                                                    } }, { children: _jsx(Box, __assign({ component: 'span', sx: {
                                                            fontSize: '1.182rem',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            marginBottom: '0.1rem',
                                                            marginRight: '0.25rem',
                                                        } }, { children: _jsx(InfoIcon, { fill: '#9f9f9f', width: '1rem', height: '1rem' }, void 0) }), void 0) }), void 0) }), void 0) }), void 0)) : null] }), "select_modal_radio_".concat(v.id)));
                            }
                            else {
                                contents = (_jsxs(Fragment, { children: [_jsx(StyledFormControlLabel, { id: "radio_".concat(i), 
                                            // key={`select_modal_radio_${v.id}`}
                                            style: { pointerEvents: 'none' }, value: v.id, label: v.name ? v.name : '', labelPlacement: 'start', control: _jsx(StyledRadio, { style: { pointerEvents: 'auto' }, disableRipple: true, color: 'green', checked: value[0] && v.id === value[0].id }, void 0), sx: { span: { pointerEvents: 'auto' } } }, void 0), v.id === 'p' ? (_jsx(Box, { sx: {
                                                margin: '1rem 0 1rem 1rem',
                                                width: 'calc(100% - 0.5rem)',
                                                borderBottom: 'solid 1px #dee1de',
                                            } }, void 0)) : null] }, "select_modal_radio_".concat(v.id)));
                            }
                            return contents;
                        }) }), void 0)) : (_jsx(StyledFormGroup, __assign({ id: 'wrap_el' }, { children: contents === null || contents === void 0 ? void 0 : contents.map(function (v, i) { return (_jsx(StyledFormControlLabel, { style: { pointerEvents: 'none' }, label: v.name, labelPlacement: 'start', control: _jsx(StyledCheckbox, { checked: v.checked, onChange: function () { return onChangeCheckbox(v.checked == true, i); }, name: v.name, style: { pointerEvents: 'auto' }, color: 'green', icon: noSelect && v.id != 'all_no' ? (_jsx(Square, { className: 'checkbox_square' }, void 0)) : (_jsx(CheckBoxOutlineBlankOutlined, {}, void 0)), disableRipple: noSelect && v.id != 'all_no' }, void 0), disabled: noSelect && v.id != 'all_no', sx: { span: { pointerEvents: 'auto' } } }, "select_modal_check_".concat(v.id))); }) }), void 0)), modal_id === 'remain_account_count' ? (_jsx(Button, __assign({ disableRipple: true, sx: {
                            backgroundColor: '#f4f4f4',
                            width: '59%',
                            alignSelf: 'center',
                            borderRadius: '14px',
                            color: 'var(--gray-1)',
                            height: '2rem',
                            fontSize: '1rem',
                            '&:hover': {
                                backgroundColor: '#f4f4f4',
                            },
                        }, onClick: onClickOverCount }, { children: "+ \uC218\uAC15\uB8CC\uB97C \uBBF8\uB9AC \uBC1B\uC558\uC5B4\uC694." }), void 0)) : null, _jsx(StyledButton, __assign({ onClick: onClick, disableRipple: true, className: hasScroll ? 'scroll' : '' }, { children: checkValue() ? (_jsx(Box, __assign({ component: 'span', sx: { color: 'green.main' } }, { children: "\uD655\uC778" }), void 0)) : (_jsx(Box, __assign({ component: 'span', sx: { color: 'black.main' } }, { children: "\uCDE8\uC18C" }), void 0)) }), void 0)] }, void 0) }), void 0) }), void 0));
}
export default ModalSelect;
