var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { useEffect, useState, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled, Box } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { fetchGetApi } from '../../../src/utils/api';
import { GoTriangleDown } from 'react-icons/go';
import { ModalContext } from '../../provider/ModalProvider';
import { SET_UPDATE } from '../../store/common/searchbar';
import { getIdRCheckday } from '../../utils/tools';
var StyledMenu = styled(function (props) { return (_jsx(Menu, __assign({ elevation: 0, anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    }, transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    } }, props), void 0)); })(function (_a) {
    var theme = _a.theme, style = _a.style, props = __rest(_a, ["theme", "style"]);
    return ({
        '& .MuiPaper-root': __assign(__assign({ transform: props.isZoom ? 'scale(0.9) !important' : '', borderRadius: 2, marginTop: theme.spacing(0), minWidth: '9rem', color: 'var(--black)', fontSize: '1rem', border: '1px solid var(--gray-2)', 
            // borderTop: 'none',
            maxHeight: props.parent_id === 'sms_send' ? '70vh' : '18rem', 
            // width: '9rem',
            overflowX: 'auto' }, style), { '& .MuiMenu-list': {
                width: '100%',
                padding: '4px 0',
            }, '& .MuiMenuItem-root': {
                height: '40px',
                padding: '0 1rem',
                overflow: 'hidden',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                div: {
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                },
            }, '.down': {
                color: '#c0c0c0',
            } }),
    });
});
var StyledButton = styled(function (_a) {
    var isStatistics = _a.isStatistics, rest = __rest(_a, ["isStatistics"]);
    return (_jsx(Button, __assign({}, rest), void 0));
})(function (_a) {
    var isStatistics = _a.isStatistics;
    return ({
        minWidth: isStatistics ? '7.5rem' : '9rem',
        height: '100%',
        backgroundColor: 'var(--white)',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 1rem',
        borderRadius: 4,
        fontSize: '1rem',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'var(--white)',
        },
        svg: {
            width: '0.875rem',
            height: '0.875rem',
            marginTop: '-2px',
        },
    });
});
var DetailDeco = styled('div')(function () { return ({
    width: '0.875rem',
    height: '0.875rem',
    borderBottom: '1px dotted var(--gray-1)',
    borderLeft: '1px dotted var(--gray-1)',
    position: 'absolute',
    top: 'calc(22px - 0.5rem)',
    left: '1rem',
    transform: 'translateY(-50%)',
}); });
var ExposureDivider = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        width: '90%',
        height: '2px',
        backgroundColor: '#f7f7f7',
        margin: '0.25rem auto',
    });
});
function DropdownNormal(props) {
    var _this = this;
    var dispatch = useDispatch();
    var user_info = useSelector(function (state) { return state.UserReducer; });
    // const { root_visible } = useContext(ModalContext);
    var _a = React.useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var open = Boolean(anchorEl);
    var id = props.id;
    var contents = props.contents;
    var type = props.type;
    var onClickItem = props.onClickItem;
    var className = props.className;
    var disabled = props.disabled;
    var check_count = props.checkCount;
    var use_disabled = props.useDisabled;
    var title = props.title;
    var setTitle = props.setTitle;
    var sx = props.sx;
    var menu_style = props.menuStyle;
    var disable_owner = props.disableOwner;
    var _b = useState([]), dropdownContents = _b[0], setDropdownContents = _b[1];
    var _c = useState(''), dropdownTitle = _c[0], setDropdownTitle = _c[1];
    var _d = useState([]), classList = _d[0], setClassList = _d[1];
    var _e = useState(false), clickStatus = _e[0], setClickStatus = _e[1];
    var _f = useState([]), originClassList = _f[0], setOriginClassList = _f[1];
    var _g = useState(null), exposureIndex = _g[0], setExposureIndex = _g[1];
    var checkCntRef = useRef();
    var modal_alert = useContext(ModalContext).modal_alert;
    useEffect(function () {
        return function () {
            setDropdownContents([]);
            setDropdownTitle('');
            setClassList([]);
            setClickStatus(false);
            setExposureIndex(null);
        };
    }, []);
    useEffect(function () {
        if (type == 'class' || type == 'class_day' || type == 'class_day_all') {
            setDropdownTitle(type == 'class' ? '클래스 전체' : '출석요일 기준');
            setClassDropdownContents();
        }
        else if (type === 'account_calendar') {
            setDropdownContents([
                {
                    title: '결제일 기준',
                    listView: (_jsx(_Fragment, { children: _jsx(Box, __assign({ component: 'span', sx: { paddingLeft: '1.35rem' } }, { children: '결제일 기준' }), void 0) }, void 0)),
                },
                {
                    title: '정산일 기준',
                    listView: (_jsx(_Fragment, { children: _jsx(Box, __assign({ component: 'span', sx: { paddingLeft: '1.35rem' } }, { children: '정산일 기준' }), void 0) }, void 0)),
                },
            ]);
        }
    }, []);
    useEffect(function () {
        setCurrentTitle();
    }, [contents, title]);
    useEffect(function () {
        if (open && contents) {
            var exposure_idx = contents.findIndex(function (item) { return "".concat(item).includes('exposure'); });
            if (Number(exposure_idx) >= 0) {
                setExposureIndex(exposure_idx);
            }
        }
    }, [open]);
    var setCurrentTitle = function () {
        if (contents && contents.length > 0) {
            if (type == 'custom' && title) {
                setDropdownTitle(title);
            }
            else {
                setDropdownTitle('관리');
            }
            setDropdownContents(__spreadArray([], contents, true));
        }
    };
    useEffect(function () {
        if (!open) {
            setCurrentTitle();
            setClickStatus(false);
        }
    }, [open]);
    var setClassDropdownContents = function () { return __awaiter(_this, void 0, void 0, function () {
        var class_res, class_list, tmp_class_list, dropdown_items, tmp_dropdown_list, tmp_dropdown_list;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchGetApi("/".concat(user_info.cid, "/user/").concat(user_info.uid, "/class/all"))];
                case 1:
                    class_res = _a.sent();
                    if (class_res.message != 'null') {
                        class_list = class_res.message.rows;
                        setOriginClassList(__spreadArray([], class_list, true));
                        tmp_class_list = class_list.map(function (item) {
                            var _a;
                            return { label: (_a = item.rname) !== null && _a !== void 0 ? _a : '-', id: Number(item.rid) };
                        });
                        dropdown_items = class_list.map(function (item) {
                            var _a;
                            var rname = (_a = item.rname) !== null && _a !== void 0 ? _a : '-';
                            return type == 'class'
                                ? rname
                                : {
                                    title: rname,
                                    listView: (_jsxs(_Fragment, { children: [_jsx(DetailDeco, {}, void 0), _jsx(Box, __assign({ component: 'span', sx: { paddingLeft: '1.35rem' } }, { children: rname }), void 0)] }, void 0)),
                                };
                        });
                        tmp_dropdown_list = __spreadArray([], dropdown_items, true);
                        if (type == 'class') {
                            // `${type == 'class' ? '클래스 전체' : '출석요일 기준'}`,
                            tmp_dropdown_list.unshift('클래스 전체');
                            if (id && ['sms_send'].includes(id)) {
                                tmp_dropdown_list.push('휴원생', '퇴원생', '미등록생');
                            }
                        }
                        else if (type == 'class_day_all') {
                            tmp_dropdown_list.unshift('출석요일 기준', '당일출석 기준');
                        }
                        else {
                            tmp_dropdown_list.unshift('출석요일 기준');
                        }
                        setClassList(__spreadArray([], tmp_class_list, true));
                        setDropdownContents(tmp_dropdown_list);
                    }
                    else {
                        tmp_dropdown_list = ["".concat(type == 'class' ? '클래스 전체' : '출석요일 기준')];
                        setClassList([]);
                        setDropdownContents(tmp_dropdown_list);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    function handleMenuClick(idx) {
        if (type == 'class' || type == 'class_day' || type == 'class_day_all') {
            dispatch({
                type: SET_UPDATE,
                payload: {
                    update: true,
                },
            });
            if (idx == 0) {
                onClickItem('all');
            }
            else {
                if (idx == 1 && type == 'class_day_all') {
                    onClickItem('all_without_checkday');
                }
                else {
                    var uclass_arr = ['휴원생', '퇴원생', '미등록생'];
                    if (id && ['sms_send'].includes(id) && uclass_arr.includes("".concat(dropdownContents[idx]))) {
                        var uclass_ids = {
                            휴원생: 'SB',
                            퇴원생: 'SX',
                            미등록생: 'SN',
                        };
                        onClickItem(uclass_ids["".concat(dropdownContents[idx])]);
                    }
                    else {
                        var minus_num = 1;
                        if (type == 'class_day_all') {
                            minus_num = 2;
                        }
                        var rcheckday_string = getIdRCheckday(originClassList[idx - minus_num].rcheckday.replace(/[,]/g, ', '));
                        onClickItem(classList[idx - minus_num].id, rcheckday_string);
                    }
                }
            }
        }
        else {
            onClickItem(idx);
        }
        var title;
        if (typeof dropdownContents[idx] === 'string') {
            title = "".concat(dropdownContents[idx]);
        }
        else {
            title = "".concat(dropdownContents[idx].title);
        }
        title = title.replace('_require', '');
        title = title.replace('::disabled', '');
        title = title.replace('::exposure', '');
        setDropdownTitle(title);
        if (setTitle) {
            var title_1;
            if (typeof dropdownContents[idx] === 'string') {
                title_1 = "".concat(dropdownContents[idx]);
            }
            else {
                title_1 = "".concat(dropdownContents[idx].title);
            }
            title_1 = title_1.replace('_require', '');
            title_1 = title_1.replace('::disabled', '');
            title_1 = title_1.replace('::exposure', '');
            setTitle(title_1);
        }
        setClickStatus(true);
        handleClose();
    }
    var handleClick = function (event) {
        if (Number.isInteger(check_count) && check_count === 0) {
            modal_alert.openModalAlert('empty', '내역에서 항목 체크 후, 다시 선택해주세요.');
            return;
        }
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var setMenuDisabled = function (text) {
        if (use_disabled && typeof text == 'string') {
            if (text.indexOf('::disabled') >= 0) {
                if (text.indexOf('disabled_require') >= 0) {
                    return true;
                }
                else {
                    if (check_count == 1) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };
    useEffect(function () {
        if (checkCntRef.current === 0 && check_count === 1) {
            var btn = document.getElementById('demo-customized-button');
            if (!btn)
                return;
            var ripple_1 = document.createElement('span');
            ripple_1.classList.add('dropdown-ripple');
            btn.appendChild(ripple_1);
            setTimeout(function () {
                ripple_1.remove();
            }, 2000);
        }
        checkCntRef.current = check_count;
        (function () {
            var ripple = document.querySelector('.ripple');
            if (ripple) {
                ripple.remove();
            }
        });
    }, [check_count]);
    return (_jsxs(_Fragment, { children: [_jsx(StyledButton, __assign({ id: 'demo-customized-button', "aria-controls": open ? 'demo-customized-menu' : undefined, "aria-haspopup": 'true', "aria-expanded": open ? 'true' : undefined, variant: 'outlined', disableElevation: true, disableRipple: true, color: 'blue', onClick: handleClick, endIcon: _jsx(GoTriangleDown, {}, void 0), className: className, disabled: disabled, sx: __assign(__assign({}, sx), (Number.isInteger(check_count)
                    ? {
                        backgroundColor: check_count === 0 ? '#ffffff !important' : '#00bfff !important',
                        color: check_count === 0 ? '#00aeff !important' : '#ffffff !important',
                        fontWeight: 600,
                        border: check_count === 0 ? '1px solid #00aeff !important' : '#00bfff !important',
                        overflow: 'hidden',
                    }
                    : {})), isStatistics: props.isStatistics }, { children: title ? title : dropdownTitle }), void 0), _jsx(StyledMenu, __assign({ id: 'demo-customized-menu', MenuListProps: {
                    'aria-labelledby': 'demo-customized-button',
                }, anchorEl: anchorEl, open: open, onClose: handleClose, style: menu_style, parent_id: id, isZoom: user_info.zoom_status == 90 }, { children: dropdownContents.length > 0 ? (dropdownContents.map(function (item, idx) {
                    return [
                        exposureIndex == idx ? _jsx(ExposureDivider, {}, void 0) : null,
                        _jsx(MenuItem, __assign({ onClick: function () { return handleMenuClick(idx); }, disableRipple: true, disabled: disable_owner
                                ? disable_owner
                                : typeof item == 'string'
                                    ? setMenuDisabled(item)
                                    : setMenuDisabled(item.title) }, { children: _jsx(Box, __assign({ sx: { positon: 'relative' }, className: ['휴원생', '퇴원생', '미등록생'].includes("".concat(item)) ? 'down' : '' }, { children: typeof item == 'string'
                                    ? item.replace('::disabled', '').replace('_require', '').replace('::exposure', '')
                                    : item.listView }), void 0) }), "menu_item_".concat(idx)),
                    ];
                })) : (_jsx(MenuItem, __assign({ className: 'empty', disableRipple: true, disabled: true }, { children: "\uD074\uB798\uC2A4\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4." }), void 0)) }), void 0)] }, void 0));
}
export default DropdownNormal;
